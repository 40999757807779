import React from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
const { v4: uuidv4 } = require('uuid')
import { FaStar } from 'react-icons/fa'
import { GatsbyImage } from 'gatsby-plugin-image'

// App components
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, colors } from '../../utils/theme'

const experiences = [
  {
    stars: 5,
    imageName: 'MohamedHafsa',
    name: 'Hafsa M.',
    text: 'Collins orthodontics is an amazing place to go. The customer service there is amazing. They also do braces very well. I love my smile and if you want to you should go to Collins orthodontics!!',
  },
  {
    stars: 5,
    imageName: 'FrostRenee',
    name: 'Renee F.',
    text: 'Collins More than exceeded my expectations! I LOVE my new smile thanks Collins Orthodontics for giving me the smile I’ve always wanted.',
  },
  {
    stars: 5,
    imageName: 'OgrenEmma',
    name: 'Emma O.',
    text: `My teeth are just perfect and now I’m not afraid to smile!`,
  },
  {
    stars: 5,
    imageName: 'HuebscherHannah',
    name: 'Hannah H.',
    text: 'The entire Collins team was a dream to work with. They are truly welcoming to anyone walking through their doors, and I looked forward to my visits. The staff is so friendly and works so efficiently, I considered changing my career just to be able to work with them. I am so thrilled to have the smile Dr. Collins transformed for me, and would go back if I could.',
  },
  {
    stars: 5,
    imageName: 'RepedeWilliam',
    name: 'William R.',
    text: "Our son just got his braces off and we're thrilled with the results! Not only that, but Dr. Collins and the team have also been great to work with. Highly recommended!",
  },
  {
    stars: 5,
    imageName: 'SchneiderRyan',
    name: 'Ryan S.',
    text: 'Can’t say enough good things about Collins! Finished my orthodontic treatment yesterday and can’t stop smiling! The staff are always so welcoming and will go the extra mile for everyone! Highly recommend!',
  },
  {
    stars: 5,
    imageName: 'DicksonCassie',
    name: 'Cassie D.',
    text: 'Awesome staff! Everyone is always friendly and welcoming. The team made my Invisalign journey easy and went above and beyond to continue my care even when I relocated out of state!',
  },
  {
    stars: 5,
    imageName: 'VuSohale',
    name: 'Sohale V.',
    text: 'Awesome team and great work! I would recommend Collins Orthodontics to anyone! They have been such a pleasure to work with!! Thank you!!',
  },
  {
    stars: 5,
    imageName: 'QuickKristen',
    name: 'Kristen Q.',
    text: "Thank you to the Collins Orthodontics team for making my braces experience so positive! Everyone on the team is so friendly and the office culture is just plain fun (best playlist ever - I'm following on Spotify!). Dr. Collins is a perfectionist, an attribute his patients are fortunate to benefit from. I can't recommend Collins enough... I'm all smiles just thinking about this dream team!",
  },
  {
    stars: 5,
    imageName: 'EsserAddy',
    name: 'Addy E.',
    text: 'Just got my clear braces off today and I am amazed how great my smile looks after only having them on for a little over a year! Everyone at Collins Orthodontics are great to work with. Wouldn’t have changed anything about my braces experience!',
  },
  {
    stars: 5,
    imageName: 'EikenSloan',
    name: 'Sloan E.',
    text: 'Amazing staff, service & results— nothing but the best from Dr. Collins and his team!!',
  },
  {
    stars: 5,
    imageName: 'CobleMichael',
    name: 'Michael C.',
    text: 'Every time I walked into Collins I knew I was going to get their best care',
  },
  {
    stars: 5,
    imageName: 'WingBreanna',
    name: 'Breanna W.',
    text: 'The Collins Orthodontics team was very nice and respectful every appointment. They love every single patient that comes in. Love my smile, thanks to them!!',
  },
  {
    stars: 5,
    imageName: 'PeetChristie',
    name: 'Christie P.',
    text: 'Dr. Collins and his team are amazing! They all take the time to get you know you as well as make your smile beautiful! Their customer service is out of this world! I always looked forward to my future appointments. It is very easy to schedule appointments that will work around your busy schedule. I highly recommend this team! :)',
  },
  {
    stars: 5,
    imageName: 'ChittanavongLisa',
    name: 'Lisa C.',
    text: "I recently got my braces off and I'm proud to see I am happy to smile with my new teeth. I always recommend them to my friends and to strangers I meet at work. I will never regret coming here. Its such a nice and welcoming environment I'm sure anyone will fit right in with them.",
  },
  {
    name: 'Abby S.',
    stars: 5,
    imageName: 'SchulteAbigail',
    text: 'I had such a great experience at Collins Orthodontics! Dr. Collins and his team are so wonderful to work with! Dr. Collins takes the time to get to know you, and truly cares about the needs and wants of his patients. There were several times I had to move appointments, or get in sooner, and they made it work for me every single time. Everyone there is so positive and upbeat. It is always a wonderful atmosphere to walk into! I would highly recommend going here to everyone!',
  },
  {
    name: 'Noah W.',
    stars: 5,
    imageName: 'WoxlandNoah',
    text: 'Collins Orthodontics is fantastic. The staff are super friendly and easy to talk to, and always make you feel welcomed and appreciated. Dr. Collins himself is no exception; he always has a smile on his face. My braces worked perfectly; my smile looks awesome! Thanks Collins Orthodontics!',
  },
  {
    name: 'Tara B.',
    stars: 5,
    imageName: 'BraunGreta',
    text: "Collins Orthodontics is not just a 'business' and you can feel it when you walk through the door. The true compassion for humankind that Dr. Collins and his staff display day in and day out is outstanding and they should be so proud! Cheers to all the wonderful smiles you give out! Our daughter is glowing in her new and absolutedly stunning smile! Thank you!",
  },
  {
    name: 'Shivani K.',
    stars: 5,
    imageName: 'KushwahaShivani',
    text: 'I have nothing but positive things to say about Collins Orthodontics. Dr. Collins and his team are so friendly, enthusiastic, and knowledgeable. The team was always accommodating with my busy college schedule. I am so happy with my final result. Thank you Dr. Collins!',
  },
  {
    stars: 5,
    imageName: 'RomeroIsidoro',
    name: 'Isidro R.',
    text: (
      <div>
        <p>
          Me encanta mi nueva sonrisa gracias al equipo de ortodoncia de
          Collins! Fueron muy amables y siempre trataron de acomodar mis citas,
          para que no interferiera con mi horario de trabajo. Yo se los
          recomiendo totalmente a todos los que necesiten servicios de
          ortodoncista.
        </p>
        <p>
          Translation: "I love my new smile thanks to the Collins braces team!
          They were very kind and always tried to accommodate my appointments,
          so I didn't get involved with my work schedule. I totally recommend
          all those who need orthodontist services"
        </p>
      </div>
    ),
  },
  {
    stars: 5,
    imageName: 'AdebayoDami',
    name: 'Dami A.',
    text: `Tired of being insecure and self-conscious about your smile because of those gaps in your teeth? Because I know I was and that’s when I decided to take a step in the right direction to try and fix it. Like most people, I did my research to try and find the right orthodontist that would fit me and not break the bank. That’s when I came unto Collins Orthodontics and I’ve never looked back on my decision. As soon as I first walked into their office, I was welcomed with warm smiles and positive energy. Impressions were done within the first visit and Dr. Collins gave me a plan on how long it would take to have my braces on and the price of it. At the time, I was a full time nursing student and "extra money" was something I didn’t have. Dr. Collins and his team were able to give me financing options that would best suits me. If you’re thinking about getting braces but are unsure, I would recommend you to give them a call. This is the best place in Rochester to get your orthodontic care.`,
  },
  {
    name: 'Lori F.',
    stars: 5,
    imageName: 'FinstadLori',
    text: "I've always been unhappy with my smile but thought I was too old to do something about it. Well, I was wrong! Dr. Collins and his staff have been absolutely the best right from the start when they reassured me that I could obtain my dream of straight teeth. They are so professional and always made me feel as though I was a member of their family. My whole experience was positive from the day I walked through the front door to the day I walked out the door with a brand new smile! If you're wondering or thinking about doing something for your smile, (at any age) don't hesitate-you'll be in great hands!",
  },
  {
    stars: 5,
    imageName: 'ObergChristopher',
    name: 'Chris O.',
    text: (
      <div>
        <p>Here's what you need to know:</p>
        <ol>
          <li>
            From the first interview all the way through the process to the end,
            is simple and not intimidating for an adult or child.
          </li>
          <li>The place is filled with fantastic personalities.</li>
          <li>
            There is some down time at appointments, so they give you free drink
            coupons to go next door for awesome Dunn Bros drinks. Brilliant!
          </li>
          <li>Appointment reminders and easy rescheduling.</li>
          <li>
            If great customer service had a nickname, it would be Collins!
          </li>
        </ol>
      </div>
    ),
  },
  {
    stars: 5,
    imageName: 'MarksAshley',
    name: 'Ashley S.',
    text: "I worked with Dr. Collins and his team for 2 years while I had adult braces. It was the most amazing experience and my only regret was that I didn't get braces sooner! Dr. Collins was incredibly accommodating and his prices were by far the best I had seen while shopping around. The process of getting my braces was seamless and the team were amazing at helping me with the transition. I was able to stop in any time I had any issues and received a ton of education. After getting my braces off my smile is beyond anything I could have imagined. I can't say enough how great it is that Dr. Collins is a self proclaimed perfectionist. Every little change he makes is worth it in the end. Two years sounded like an eternity to me when I started but you want to make all your efforts worth it! The team makes those years fly by and the changes you see along the way help. The team at Collins were absolutely amazing-- the most friendly people I know. I was having a hard day once when I stopped in for a quick appointment-- the girls ran next door to grab me a coffee and even called to make sure I was ok later. There is no way put into words how wonderful everyone was to work with.",
  },
]

const styles = {
  reviewElementsHodler: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  reviewItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '550px',
    width: '100%',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  image: {
    width: '100%',
    maxWidth: '250px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: rhythm(1),
    flex: 1,
  },
  starsContainer: {},
  name: {},
  text: {},
}

const ReviewItem = ({ name, stars, imageResponsive, text }) => {
  const starElements = Array.from(Array(stars)).map((a) => (
    <FaStar key={a} size={15} color={colors.brightBlue} />
  ))
  return (
    <div css={styles.reviewItemContainer}>
      <GatsbyImage
        image={imageResponsive}
        css={styles.image}
        alt="Review item"
        key={imageResponsive.id}
      />
      <div css={styles.textContainer}>
        <div css={styles.starsContainer}>{starElements}</div>
        <h2 css={styles.name}>{name}</h2>
        <p css={styles.text}>{text}</p>
      </div>
    </div>
  )
}

class Page extends React.Component {
  render() {
    const reviewElements = experiences.map((exp) => {
      const imageResponsive =
        this.props.data[exp.imageName].childImageSharp.gatsbyImageData
      imageResponsive.id = uuidv4()

      return (
        <ReviewItem key={exp.name} {...exp} imageResponsive={imageResponsive} />
      )
    })
    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="Patient Experiences"
            bannerKey={'WHY_COLLINS'}
            hidePhoto={true}
            titleBackgroundColor={colors.brightBlue}
          />
          <div
            css={{
              backgroundColor: 'black',
              marginBottom: rhythm(1),
              marginTop: rhythm(-3 / 4),
            }}
          >
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '800px',
                margin: '0 auto',
                backgroundColor: 'black',
                width: '100%',
              }}
            >
              <div
                css={{
                  position: 'relative',
                  overflow: 'hidden',
                  paddingTop: '56.25%',
                  width: '100%',
                }}
              >
                <iframe
                  css={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 0,
                  }}
                  src="https://www.youtube.com/embed/32xmCxTMOjA"
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          </div>
          <div css={styles.reviewElementsHodler}>{reviewElements}</div>
        </div>
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query {
    AdebayoDami: file(
      relativePath: { eq: "testimonialPhotos/AdebayoDami.jpg" }
    ) {
      ...fluidImage400
    }
    BraunGreta: file(relativePath: { eq: "testimonialPhotos/BraunGreta.jpg" }) {
      ...fluidImage400
    }
    ChittanavongLisa: file(
      relativePath: { eq: "testimonialPhotos/ChittanavongLisa.jpg" }
    ) {
      ...fluidImage400
    }
    CobleMichael: file(
      relativePath: { eq: "testimonialPhotos/CobleMichael.jpg" }
    ) {
      ...fluidImage400
    }
    DicksonCassie: file(
      relativePath: { eq: "testimonialPhotos/DicksonCassie.jpg" }
    ) {
      ...fluidImage400
    }
    EikenSloan: file(relativePath: { eq: "testimonialPhotos/EikenSloan.jpg" }) {
      ...fluidImage400
    }
    EsserAddy: file(relativePath: { eq: "testimonialPhotos/EsserAddy.jpg" }) {
      ...fluidImage400
    }
    FinstadLori: file(
      relativePath: { eq: "testimonialPhotos/FinstadLori.jpg" }
    ) {
      ...fluidImage400
    }
    FrostRenee: file(relativePath: { eq: "testimonialPhotos/FrostRenee.jpg" }) {
      ...fluidImage400
    }
    HuebscherHannah: file(
      relativePath: { eq: "testimonialPhotos/HuebscherHannah.jpg" }
    ) {
      ...fluidImage400
    }
    KushwahaShivani: file(
      relativePath: { eq: "testimonialPhotos/KushwahaShivani.jpg" }
    ) {
      ...fluidImage400
    }
    MarksAshley: file(
      relativePath: { eq: "testimonialPhotos/MarksAshley.jpg" }
    ) {
      ...fluidImage400
    }
    MohamedHafsa: file(
      relativePath: { eq: "testimonialPhotos/MohamedHafsa.jpg" }
    ) {
      ...fluidImage400
    }
    ObergChristopher: file(
      relativePath: { eq: "testimonialPhotos/ObergChristopher.jpg" }
    ) {
      ...fluidImage400
    }
    OgrenEmma: file(relativePath: { eq: "testimonialPhotos/OgrenEmma.jpg" }) {
      ...fluidImage400
    }
    PeetChristie: file(
      relativePath: { eq: "testimonialPhotos/PeetChristie.jpg" }
    ) {
      ...fluidImage400
    }
    QuickKristen: file(
      relativePath: { eq: "testimonialPhotos/QuickKristen.jpg" }
    ) {
      ...fluidImage400
    }
    RepedeWilliam: file(
      relativePath: { eq: "testimonialPhotos/RepedeWilliam.jpg" }
    ) {
      ...fluidImage400
    }
    RomeroIsidoro: file(
      relativePath: { eq: "testimonialPhotos/RomeroIsidoro.jpg" }
    ) {
      ...fluidImage400
    }
    SchneiderRyan: file(
      relativePath: { eq: "testimonialPhotos/SchneiderRyan.jpg" }
    ) {
      ...fluidImage400
    }
    SchulteAbigail: file(
      relativePath: { eq: "testimonialPhotos/SchulteAbigail.jpg" }
    ) {
      ...fluidImage400
    }
    VuSohale: file(relativePath: { eq: "testimonialPhotos/VuSohale.jpg" }) {
      ...fluidImage400
    }
    WingBreanna: file(
      relativePath: { eq: "testimonialPhotos/WingBreanna.jpg" }
    ) {
      ...fluidImage400
    }
    WoxlandNoah: file(
      relativePath: { eq: "testimonialPhotos/WoxlandNoah.jpg" }
    ) {
      ...fluidImage400
    }
  }
`
